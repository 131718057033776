<template>
  <!-- NewUser Modal -->
  <div class="modal fade" data-backdrop="static" id="NewUser" tabindex="-1" role="dialog" aria-labelledby="NewUserTitle" aria-hidden="true" ref="NewUser">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <form class="modal-content" ref="NewUserForm" @submit.prevent="createUser" @keydown="new_user.errors.clear($event.target.id);">
        <div class="modal-header">
          <h5 class="modal-title" id="newUserTitle">CREATE USER</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row no-gutters mb-1">
            <label for="first_name" class="font-weight-bold col-sm-3 col-form-label">First Name</label>
            <div class="col-sm-9">
              <input type="text" v-model="new_user.first_name" id="first_name" class="form-control form-control-sm" required :class="{ 'is-invalid': new_user.errors.has('first_name') }">
              <div v-if="new_user.errors.has('first_name')" :class="{ 'invalid-feedback text-left': new_user.errors.has('first_name') }">
                {{ new_user.errors.first('first_name') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label for="last_name" class="font-weight-bold col-sm-3 col-form-label">Last Name</label>
            <div class="col-sm-9">
              <input type="text" v-model="new_user.last_name" id="last_name" class="form-control form-control-sm" required :class="{ 'is-invalid': new_user.errors.has('last_name') }">
              <div v-if="new_user.errors.has('last_name')" :class="{ 'invalid-feedback text-left': new_user.errors.has('last_name') }">
                {{ new_user.errors.first('last_name') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label for="middle_name" class="font-weight-bold col-sm-3 col-form-label">Middle Name</label>
            <div class="col-sm-9">
              <input type="text" v-model="new_user.middle_name" id="middle_name" class="form-control form-control-sm" :class="{ 'is-invalid': new_user.errors.has('middle_name') }">
              <div v-if="new_user.errors.has('middle_name')" :class="{ 'invalid-feedback text-left': new_user.errors.has('middle_name') }">
                {{ new_user.errors.first('middle_name') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Suffix</label>
            <div class="col-sm-9">
              <input type="text" v-model="new_user.extension" id="extension" class="form-control form-control-sm" placeholder="e.g. Jr., Sr." :class="{ 'is-invalid': new_user.errors.has('extension') }">
              <div v-if="new_user.errors.has('extension')" :class="{ 'invalid-feedback text-left': new_user.errors.has('extension') }">
                {{ new_user.errors.first('extension') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label for="email" class="font-weight-bold col-sm-3 col-form-label">Email</label>
            <div class="col-sm-9">
              <input type="email" v-model="new_user.email" id="email" class="form-control form-control-sm" required :class="{ 'is-invalid': new_user.errors.has('email') }">
              <div v-if="new_user.errors.has('email')" :class="{ 'invalid-feedback text-left': new_user.errors.has('email') }">
                {{ new_user.errors.first('email') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label for="username" class="font-weight-bold col-sm-3 col-form-label">Username</label>
            <div class="col-sm-9">
              <input type="text" v-model="new_user.username" id="username" class="form-control form-control-sm" autocomplete="username" required :class="{ 'is-invalid': new_user.errors.has('username') }">
              <div v-if="new_user.errors.has('username')" :class="{ 'invalid-feedback text-left': new_user.errors.has('username') }">
                {{ new_user.errors.first('username') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label for="password" class="font-weight-bold col-sm-3 col-form-label">Password</label>
            <div class="col-sm-9">
              <input type="password" v-model="new_user.password" id="password" class="form-control form-control-sm" autocomplete="new-password" required :class="{ 'is-invalid': new_user.errors.has('password') }">
              <div v-if="new_user.errors.has('password')" :class="{ 'invalid-feedback text-left': new_user.errors.has('password') }">
                {{ new_user.errors.first('password') }}
              </div>
            </div>
          </div>

          <div class="form-group row no-gutters mb-1">
            <label for="password_confirmation" class="font-weight-bold col-sm-3 col-form-label">Confirm Password</label>
            <div class="col-sm-9">
              <input type="password" v-model="new_user.password_confirmation" id="password_confirmation" class="form-control form-control-sm" autocomplete="new-password" required
                     :class="{ 'is-invalid': new_user.errors.has('password') }">
            </div>
          </div>
          <div v-show="!new_user.vessel_id" class="form-group row no-gutters mb-1">
            <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Management Office</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="management_office_id" v-model="new_user.management_office_id" @change="changeManagementOffice($event)"
                      :class="{ 'is-invalid': new_user.errors.has('management_office_id') }" :disabled="disable.managementOffice">
                <option :value="null">—— Select Management Office ——</option>
                <option v-for="mngmntOffice in mngmntOfficeKeys" :key="mngmntOffice.id" :value="mngmntOffice.id">
                  {{ mngmntOffice.name }}
                </option>
              </select>
              <div v-if="new_user.errors.has('management_office_id')" :class="{ 'invalid-feedback text-left': new_user.errors.has('management_office_id') }">
                {{ new_user.errors.first('management_office_id') }}
              </div>
            </div>
          </div>
          <div v-show="!new_user.management_office_id" class="form-group row no-gutters mb-1">
            <label for="vessel_id" class="font-weight-bold col-sm-3 col-form-label">Vessel</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm text-uppercase" id="vessel_id" v-model="new_user.vessel_id" @change="changeVessel($event)"
                      :class="{ 'is-invalid': new_user.errors.has('vessel_id') }" :disabled="disable.vessel">
                <option :value="null">—— Select Vessel ——</option>
                <option v-for="vessel in vesselKeys" :key="vessel.id" :value="vessel.id">
                  {{ vessel.name }}
                </option>
              </select>
              <div v-if="new_user.errors.has('vessel_id')" :class="{ 'invalid-feedback text-left': new_user.errors.has('vessel_id') }">
                {{ new_user.errors.first('vessel_id') }}
              </div>
            </div>
          </div>
          <div v-show="new_user.management_office_id" class="form-group row no-gutters mb-1">
            <label for="management_office_id" class="font-weight-bold col-sm-3 col-form-label">Role Name</label>
            <div class="col-sm-9">

              <select name="userRoleSelection" id="userRoleSelection" class="form-control form-control-sm px-3" v-model="new_user.role"
                      :class="{ 'is-invalid': new_user.errors.has('role') }" :disabled="disable.role">
                <option :value="null" disabled selected hidden>--- CHOOSE ROLE ---</option>
                <option v-if="isElite()" value="super-user">Administrator (ELITE) </option>
                <option value="director">Director</option>
                <option value="general-manager">General Manager</option>
                <option value="manager">Manager</option>
                <option value="superintendent">Superintendent</option>
                <option value="instructor">Instructor</option>
                <option value="vessel" disabled>Vessel</option>
              </select>
              <div v-if="new_user.errors.has('role')" :class="{ 'invalid-feedback text-left': new_user.errors.has('role') }">
                {{ new_user.errors.first('role') }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" ref="NewUser_close" @click="resetForm" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-sm btn-primary" :disabled="new_user.errors.any()">Create User</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {UserService} from '@/services/UserService'
import {AlertService} from '@/services/AlertService'
import {Form} from "form-backend-validation";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name: 'NewUser',
  mixins:[permissionMixin],
  data() {
    return {
      new_user: new Form({
        first_name: null,
        last_name: null,
        middle_name: null,
        extension: null,
        username: null,
        password: null,
        password_confirmation: null,
        email: null,
        management_office_id: null,
        role: null,
        vessel_id: null,
      }),
      disable: {
        managementOffice: false,
        false: null,
        vessel: false,
      }
    }
  },
  methods: {
    ...mapActions(['getMngmtOfficeKeys', 'getVesselKeys']),
    resetForm() {
      this.$refs.NewUserForm.reset()
    },
    changeManagementOffice(event) {
      if (this.new_user.management_office_id) {
        this.new_user.vessel_id = null
        this.disable.vessel = true
      } else {
        this.disable.vessel = false
      }

      this.new_user.errors.clear(event.target.id)
    },
    changeVessel(event) {
      if (this.new_user.vessel_id) {
        this.new_user.role = 'vessel'
        this.disable.role = true
        this.new_user.management_office_id = null
        this.disable.managementOffice = true
      } else {
        this.disable.managementOffice = false
        this.new_user.role = null
        this.disable.role = false
      }

      this.new_user.errors.clear(event.target.id)
    },
    createUser() {
      UserService.createUser(this.new_user)
        .then(response => {
          // close NewUser modal
          this.$refs.NewUser_close.click()
          AlertService.successAlert('User has been created', 'CREATE USER')

          // notify $parent to <new-user @resetPage="setPage"/>
          this.$emit('resetPage')
        })
        .catch(error => {
          AlertService.errorAlert(error.response.data.message, 'CREATE USER')
        });
    },
  },
  async created() {
    await this.getMngmtOfficeKeys()
    await this.getVesselKeys()
  },
  computed: {
    ...mapGetters(['mngmntOfficeKeys', 'vesselKeys'])
  }
}
</script>

<style scoped>

</style>
